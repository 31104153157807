import React, { memo } from 'react';
import { ActionIcon, Box, Center, Text, Tooltip } from '@mantine/core';
import { IconCheck, IconCopy } from '@tabler/icons-react';
import PropTypes from 'prop-types';
import { useClipboard } from '@mantine/hooks';

const copyButtonLocationStyle = { position: 'absolute', top: '0px', right: '0px', transform: 'translate(120%, 10%)' }
const tooltipEvents = { hover: true, touch: true, focus: false }

export const CopyableContent = memo(function CopyableContent ({ contents }) {
  const clipboard = useClipboard(clipboardConfig)
  const copy = (event) => {
    event.stopPropagation()
    clipboard.copy(contents)
    return false
  }

  return (
    <Tooltip label={clipboard.copied ? 'Copied' : 'Copy'} events={tooltipEvents} withArrow position='bottom'>
      <Center>
        <Box pos='relative' onClick={copy} mr='md'>
          <Text ta='center'>
            {contents}
          </Text>
          <Box style={copyButtonLocationStyle}>
            <ActionIcon color={clipboard.copied ? 'teal' : 'gray'} variant='subtle' size='xs' onClick={copy} aria-label='Copy content'>
              {clipboard.copied
                ? (
                  <IconCheck size='1rem' />
                  )
                : (
                  <IconCopy size='1rem' />
                  )}
            </ActionIcon>
          </Box>
        </Box>
      </Center>
    </Tooltip>
  )
})

const clipboardConfig = { timeout: 2500 }

CopyableContent.propTypes = {
  contents: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
