/* eslint react/prop-types: 0 */
import { getAccessibilityDescription, QuestionType } from '../../js/generated/enums/QuestionType';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Group,
  Stack,
  Radio,
  Textarea,
  TextInput,
  Select,
  Text,
  Center,
  SimpleGrid,
  Title,
  Box,
  Button,
  Space,
  NumberFormatter,
  Indicator, FileInput
} from '@mantine/core';
import {
  CorrectAnswerIndicator,
  useAdditionalRespondentAnswer,
  useRespondentAnswer,
  getExpertiseLabelFromIndex,
  getAdequacyLabelFromIndex,
  getSatisfactoryLabelFromIndex,
  useMultipleRespondentAnswer,
  useFreeResponseAnswerTakeFocus
} from './PublishedAnswerHooks';
import { useDispatch, useSelector } from 'react-redux';
import { selectRespondentAnswerById } from './respondentAnswersSlice';
import { selectAllowDeclineAnswers, uploadFileResponse } from './assessmentsSlice';
import { logicValidate } from './logicSlice';
import { selectPrintFullColor } from './exportsSlice';
import { AnswersDistributionReport } from '../hire/cycle/invites/stages/AnswersDistributionReport';
import { notifications } from '@mantine/notifications';
import { ACCEPT } from '../hire/components/HireFileInput/HireFileInput';
import _ from 'lodash';
import { FILE_EXTENSIONS } from '../hire/file/FileUpload';
import { useParams } from 'react-router-dom';

/**
 * @param {PublishedQuestion} question
 */
export function PublishedQuestionAnswers ({ question }) {
  const component = getComponentForQuestionType(question)
  return (
    <>
      {(!!component) && question.show && <>
          <Box w='100%' pos='relative'>
            {component}
            {!question.showDistributionReport && (
              <SkipOrDeclineOption
                questionId={question.id}
                alertIfSkipped={question.answerRequired}
                mustAnswer={question.mustAnswer}
                assessmentId={question.location.assessmentId}
              />
            )}
          </Box>
        </>
      }
    </>
  )
}

const skipOrDeclineDefaultStyle = { marginBottom: 'calc(-1 * var(--mantine-spacing-xl))', marginLeft: 'var(--mantine-spacing-xxxs)', position: 'absolute', left: 0, bottom: 0 }
const skipOrDeclineDefaultRequiredStyle = { marginBottom: 'calc(-1 * var(--mantine-spacing-xl))', marginRight: 'var(--mantine-spacing-xxxs)', position: 'absolute', right: 0, bottom: 0 }

function SkipOrDeclineOption ({ questionId, alertIfSkipped, mustAnswer, assessmentId }) {
  const dispatch = useDispatch()
  const respondentAnswer = useSelector(state => selectRespondentAnswerById(state, questionId))
  const showDecline = useSelector(state => selectAllowDeclineAnswers(state, assessmentId))
  const labelText = !alertIfSkipped ? 'Skip' : (showDecline && !mustAnswer ? 'Decline to answer' : 'Skip for now')
  return (
    <>
      <Space h='xs' />
      {!respondentAnswer.answered && respondentAnswer.shouldAnswer && (
        <Button
          variant='subtle'
          radius='xl'
          size='sm'
          style={skipOrDeclineDefaultStyle}
          onClick={() => dispatch(logicValidate({ id: questionId, isAdditional: false, isSkip: true, isRequiredIfSkip: mustAnswer || (alertIfSkipped && !showDecline), pageId: respondentAnswer.pageId, assessmentId: respondentAnswer.assessmentId }))}
          color={(showDecline || !alertIfSkipped) ? 'red' : 'yellow'}
        >
          <Text c={showDecline ? (respondentAnswer.declined ? 'red' : 'dimmed') : (respondentAnswer.skipped ? 'yellow' : 'dimmed')}>{labelText}</Text>
        </Button>
      )}
      {!respondentAnswer.answered && mustAnswer && (
        <Button
          variant='subtle'
          radius='xl'
          size='sm'
          style={skipOrDeclineDefaultRequiredStyle}
          color='red'
        >
          <Text c='red'>Required</Text>
        </Button>
      )}
    </>
  )
}

/**
 * Note: for use in printing to PDF only.
 * @param {PublishedQuestion} question
 */
export function PDFQuestionAnswers ({ question }) {
  const component = getComponentForQuestionType(question, true)
  return (
    <>
      {(!!component) && question.show && <>
        <Box w='100%' pos='relative'>
          {component}
          <Space h='xs' />
        </Box>
      </>
      }
    </>
  )
}

function getComponentForQuestionType (question, toPDF = false) {
  if (question.showDistributionReport) {
    return question.answerable ? <AnswersDistributionReport question={question} vertical={question.vertical} toPDF={toPDF} /> : null
  }
  switch (question.type) {
    case QuestionType.ExpertiseSelfRating: {
      return <ExpertiseSelfRatingAnswers question={question} toPDF={toPDF} />
    }
    case QuestionType.PoorAdequateImpressive: {
      return <PoorAdequateImpressiveAnswers question={question} toPDF={toPDF} />
    }
    case QuestionType.RatingScale: {
      if (question.isDropdown) {
        return <DropdownRatingScaleAnswers question={question} toPDF={toPDF} />
      }
      return <MultipleChoiceAnswers question={question} vertical={question.vertical} toPDF={toPDF} />
    }
    case QuestionType.PoorSatisfactoryExcellent: {
      return <PoorSatisfactoryExcellentAnswers question={question} toPDF={toPDF} />
    }
    case QuestionType.MultipleChoice: {
      if (question.isDropdown) {
        return <DropdownMultipleChoiceAnswers question={question} toPDF={toPDF} />
      }
      return <MultipleChoiceAnswers question={question} vertical={question.vertical} toPDF={toPDF} />
    }
    case QuestionType.YesNo:
    case QuestionType.TrueFalse: {
      return <MultipleChoiceAnswers question={question} vertical={true} toPDF={toPDF} />
    }
    case QuestionType.Multiline: {
      return <MultilineAnswers question={question} toPDF={toPDF} />
    }
    case QuestionType.FillInTheBlank: {
      return <FillInTheBlankAnswers question={question} toPDF={toPDF} />
    }
    case QuestionType.Interview: {
      return <InterviewAnswers question={question} toPDF={toPDF} />
    }
    case QuestionType.ChooseAllThatApply: {
      return <ChooseAllThatApplyAnswers question={question} vertical={question.vertical} toPDF={toPDF} />
    }
    case QuestionType.ShortAnswer: {
      return <ShortAnswer question={question} toPDF={toPDF} />
    }
    case QuestionType.FileUpload: {
      return <FileUploadAnswer question={question} toPDF={toPDF} />
    }
    default: {
      return null
    }
  }
}

function ExpertiseSelfRatingAnswers ({ question, toPDF = false }) {
  const [answer, setAnswer] = useRespondentAnswer(question.id, question.hideFromLogic)
  const fullColor = useSelector(state => selectPrintFullColor(state))

  return (
    <Radio.Group
      value={answer}
      onChange={setAnswer}
      aria-label={`Question ${question.id} Answer Options: ${getAccessibilityDescription(question.type)}`}
    >
      <SimpleGrid cols={question.answers.length}>
        {question.answers.map((elem, index) => <ExpertiseSelfRatingAnswer key={elem.id} answer={elem} index={index} toPDF={toPDF} fullColor={fullColor} />)}
      </SimpleGrid>
    </Radio.Group>
  )
}

function ExpertiseSelfRatingAnswer ({ answer, index, toPDF = false, fullColor = false }) {
  const variant = toPDF
    ? (fullColor
        ? ('pdf-below-full-color')
        : ('pdf-below')
      )
    : 'transparent-below'
  return (
    <Radio
      variant={variant}
      value={answer.id.toString()}
      size='xl'
      label={
      <>
        <Center>
          <div dangerouslySetInnerHTML={{ __html: answer.content }}></div>
        </Center>
        <Center>
          {getExpertiseLabelFromIndex(index)}
        </Center>
        {!!answer.score && <Center><AnswerScore score={answer.score} /></Center>}
      </>
      }
    />
  )
}

function PoorAdequateImpressiveAnswers ({ question, toPDF = false }) {
  const [answer, setAnswer] = useRespondentAnswer(question.id, question.hideFromLogic)
  const fullColor = useSelector(state => selectPrintFullColor(state))

  return (
    <Radio.Group
      value={answer}
      onChange={setAnswer}
      aria-label={`Question ${question.id} Answer Options: ${getAccessibilityDescription(question.type)}`}
    >
      <SimpleGrid cols={question.answers.length}>
        {question.answers.map((elem, index) => <PoorAdequateImpressiveAnswer key={elem.id} answer={elem} index={index} toPDF={toPDF} fullColor={fullColor} />)}
      </SimpleGrid>
    </Radio.Group>
  )
}

function PoorAdequateImpressiveAnswer ({ answer, index, toPDF = false, fullColor = false }) {
  const variant = toPDF
    ? (fullColor
        ? ('pdf-below-full-color')
        : ('pdf-below')
      )
    : 'transparent-below'
  return (
    <Radio
      variant={variant}
      value={answer.id.toString()}
      size='xl'
      label={
        <>
          <Center>
            {(index + 1).toString()}
          </Center>
          <Center>
            {getAdequacyLabelFromIndex(index)}
          </Center>
          {!!answer.score && <Center><AnswerScore score={answer.score} /></Center>}
        </>}
    />
  )
}

function PoorSatisfactoryExcellentAnswers ({ question, toPDF = false }) {
  const [answer, setAnswer] = useRespondentAnswer(question.id, question.hideFromLogic)
  const fullColor = useSelector(state => selectPrintFullColor(state))

  return (
    <Radio.Group
      value={answer}
      onChange={setAnswer}
      aria-label={`Question ${question.id} Answer Options: ${getAccessibilityDescription(question.type)}`}
    >
      <SimpleGrid cols={question.answers.length}>
        {question.answers.map((elem, index) => <PoorSatisfactoryExcellentAnswer key={elem.id} answer={elem} index={index} toPDF={toPDF} fullColor={fullColor} />)}
      </SimpleGrid>
    </Radio.Group>
  )
}

function PoorSatisfactoryExcellentAnswer ({ answer, index, toPDF = false, fullColor = false }) {
  const variant = toPDF
    ? (fullColor
        ? ('pdf-below-full-color')
        : ('pdf-below')
      )
    : 'transparent-below'
  return (
    <Radio
      variant={variant}
      value={answer.id.toString()}
      size='xl'
      label={
        <>
          <Center>
            {(index + 1).toString()}
          </Center>
          <Center>
            {getSatisfactoryLabelFromIndex(index)}
          </Center>
          {!!answer.score && <Center><AnswerScore score={answer.score} /></Center>}
        </>}
    />
  )
}

function DropdownRatingScaleAnswers ({ question, toPDF = false }) {
  const [answer, setAnswer] = useRespondentAnswer(question.id, question.hideFromLogic)

  // const answerOptions = useMemo(() => {  // TODO [allow answer text formatting/styling] asana task: Custom select implementation supporting react nodes for 'selected' label, or use stripContentTags here.
  //   return question.answers.map(elem => {
  //     return { value: elem.id.toString(), label: <div dangerouslySetInnerHTML={{ __html: elem.content }}></div> }
  //   })
  // }, [question.answers])
  //
  // const optionsFilter = ({ options, search }) => {
  //   return options
  // }
  const answerOptions = useMemo(() => {
    return question.answers.map(elem => {
      return { value: elem.id.toString(), label: elem.content }
    })
  }, [question.answers])

  return (
    <Center>
      <Select
        value={answer}
        onChange={setAnswer}
        data={answerOptions}
        placeholder='Choose response'
        aria-label={`Question ${question.id} Answer Options: ${getAccessibilityDescription(question.type)}`}
        maxDropdownHeight={200}
        w='100%'
        maw='30vw'
        withScrollArea={false}
        styles={{ dropdown: { maxHeight: 200, overflowY: 'auto' } }}
        size='xl'
      />
    </Center>
  )
}

function DropdownMultipleChoiceAnswers ({ question, toPDF = false }) {
  const [answer, setAnswer] = useRespondentAnswer(question.id, question.hideFromLogic)

  const answerOptions = useMemo(() => {
    return question.answers.map(elem => {
      return { value: elem.id.toString(), label: elem.content }
    })
  }, [question.answers])

  return (
    <Center>
      <Select
        value={answer}
        onChange={setAnswer}
        data={answerOptions}
        placeholder='Choose response'
        aria-label={`Question ${question.id} Answer Options: ${getAccessibilityDescription(question.type)}`}
        maxDropdownHeight={200}
        w='100%'
        maw='30vw'
        withScrollArea={false}
        styles={{ dropdown: { maxHeight: 200, overflowY: 'auto' } }}
        size='xl'
        searchable
        selectFirstOptionOnChange
      />
    </Center>
  )
}

function ChooseAllThatApplyAnswers ({ question, vertical = true, toPDF = false }) {
  const [answers, setAnswers] = useMultipleRespondentAnswer(question.id, question.hideFromLogic)
  const fullColor = useSelector(state => selectPrintFullColor(state))

  const accessibilityDescription = `Question ${question.id} Answer Options: ${getAccessibilityDescription(question.type)}`
  return (
    <QuestionVerticalHorizontalWrapper vertical={vertical} numberAnswers={question.answers.length}>
      {question.answers.map(elem => (
        <ChooseAllThatApplyAnswer
          key={elem.id}
          answer={elem}
          checked={answers.includes(elem.id.toString())}
          setAnswers={setAnswers}
          accessibilityDescription={accessibilityDescription}
          vertical={vertical}
          showCorrectAnswer={question.showCorrectAnswer}
          toPDF={toPDF}
          fullColor={fullColor}
        />
      ))}
    </QuestionVerticalHorizontalWrapper>
  )
}

function ChooseAllThatApplyAnswer ({ answer, checked, setAnswers, accessibilityDescription, vertical, showCorrectAnswer, toPDF = false, fullColor = false }) {
  const variant = toPDF
    ? (fullColor
        ? (vertical ? 'pdf-full-color' : 'pdf-below-full-color')
        : (vertical ? 'pdf' : 'pdf-below')
      )
    : (vertical ? 'transparent' : 'transparent-below')
  const strAnswerId = answer.id.toString()
  return (
    <MultipleChoiceAnswerPositionWrapper vertical={vertical}>
      <Radio
        variant={variant}
        size='xl'
        checked={checked}
        onChange={(event) => {
          console.debug(
            'Called on change for choose all that apply answer - update already handled in onClick.',
            event.currentTarget?.checked, checked, answer, event.currentTarget, event
          )
        }}
        onClick={() => {
          setAnswers((prev) => !checked ? prev.filter((elem) => elem !== strAnswerId).concat(strAnswerId) : prev.filter((elem) => elem !== strAnswerId))
        }}
        aria-label={accessibilityDescription}
        label={
          <MultipleChoiceAnswerPositionWrapper vertical={vertical}>
            {((!showCorrectAnswer || !answer.correct) && (!answer.score))
              ? (
                <div dangerouslySetInnerHTML={{ __html: answer.content }} />
                )
              : (
                <Group justify={vertical ? 'center' : 'flex-start'} wrap='nowrap'>
                  <div dangerouslySetInnerHTML={{ __html: answer.content }} />
                  {!!answer.score && <AnswerScore score={answer.score} />}
                  {!!showCorrectAnswer && !!answer.correct && <CorrectAnswerIndicator />}
                </Group>
                )}
          </MultipleChoiceAnswerPositionWrapper>}
      />
    </MultipleChoiceAnswerPositionWrapper>
  )
}

function MultipleChoiceAnswers ({ question, vertical = true, toPDF = false }) {
  const [answer, setAnswer] = useRespondentAnswer(question.id, question.hideFromLogic)
  const fullColor = useSelector(state => selectPrintFullColor(state))

  return (
    <Radio.Group
      value={answer}
      onChange={setAnswer}
      aria-label={`Question ${question.id} Answer Options: ${getAccessibilityDescription(question.type)}`}
    >
      <QuestionVerticalHorizontalWrapper vertical={vertical} numberAnswers={question.answers.length}>
        {question.answers.map(elem => <MultipleChoiceAnswer key={elem.id} answer={elem} vertical={vertical} showCorrectAnswer={question.showCorrectAnswer} toPDF={toPDF} fullColor={fullColor} />)}
      </QuestionVerticalHorizontalWrapper>
    </Radio.Group>
  )
}

function MultipleChoiceAnswer ({ answer, vertical, showCorrectAnswer, toPDF = false, fullColor = false }) {
  const variant = toPDF
    ? (fullColor
        ? (vertical ? 'pdf-full-color' : 'pdf-below-full-color')
        : (vertical ? 'pdf' : 'pdf-below')
      )
    : (vertical ? 'transparent' : 'transparent-below')
  return (
    <MultipleChoiceAnswerPositionWrapper vertical={vertical}>
      <Radio
        value={answer.id.toString()}
        variant={variant}
        size='xl'
        label={
        <MultipleChoiceAnswerPositionWrapper vertical={vertical}>
          {((!showCorrectAnswer || !answer.correct) && (!answer.score))
            ? (
              <div dangerouslySetInnerHTML={{ __html: answer.content }} />
              )
            : (
              <Group justify={vertical ? 'center' : 'flex-start'} wrap='nowrap'>
                <div dangerouslySetInnerHTML={{ __html: answer.content }} />
                {!!answer.score && <AnswerScore score={answer.score} />}
                {!!showCorrectAnswer && !!answer.correct && <CorrectAnswerIndicator />}
              </Group>
              )}
        </MultipleChoiceAnswerPositionWrapper>}
      />
    </MultipleChoiceAnswerPositionWrapper>
  )
}

function MultipleChoiceAnswerPositionWrapper ({ vertical, children }) {
  return (
    <>
      {vertical
        ? (
        <>
          {children}
        </>
          )
        : (
        <Center>
          {children}
        </Center>
          )}
    </>
  )
}

function QuestionVerticalHorizontalWrapper ({ vertical, numberAnswers, children }) {
  return (
    <>
      {vertical
        ? (
        <Stack gap='xs'>
          {children}
        </Stack>
          )
        : (
        <SimpleGrid cols={numberAnswers || 1}>
          {children}
        </SimpleGrid>
          )}
    </>
  )
}

function MultilineAnswers ({ question, toPDF = false }) {
  const [answer, setAnswer, , , isAnswered, isProcessing, showIndicator] = useAdditionalRespondentAnswer(question.id, question.hideFromLogic)
  const [focusRef] = useFreeResponseAnswerTakeFocus(question.id, question?.location.assessmentId, question.hideFromLogic, question.show)

  return (
    <FreeResponseIndicator isAnswered={isAnswered} isProcessing={isProcessing} showIndicator={showIndicator}>
      <Textarea
        ref={focusRef}
        value={answer}
        onChange={(event) => setAnswer(event.currentTarget.value)}
        placeholder='Enter response'
        aria-label={`Question ${question.id} Answer: ${getAccessibilityDescription(question.type)}`}
        variant='interview'
        minRows={4}
        maxRows={4}
        autosize
      />
    </FreeResponseIndicator>
  )
}

function ShortAnswer ({ question, toPDF = false }) {
  const [answer, setAnswer, setForceUpdate, { isValid, placeholder, error, icon }, isAnswered, isProcessing, showIndicator] = useAdditionalRespondentAnswer(question.id, question.hideFromLogic)
  const [focusRef] = useFreeResponseAnswerTakeFocus(question.id, question?.location.assessmentId, question.hideFromLogic, question.show, setForceUpdate)

  return (
    <Center>
      <FreeResponseIndicator isAnswered={isAnswered} isProcessing={isProcessing} showIndicator={showIndicator}>
        <TextInput
          ref={focusRef}
          value={answer}
          onChange={(event) => setAnswer(event.currentTarget.value)}
          placeholder={placeholder}
          aria-label={`Question ${question.id} Answer: ${getAccessibilityDescription(question.type)}`}
          error={isValid ? null : error}
          leftSection={icon}
          w='100%'
          maw='30vw'
          size='xl'
        />
      </FreeResponseIndicator>
    </Center>
  )
}

function FileUploadAnswer ({ question, toPDF = false }) {
  const [answer, setAnswer, setForceUpdate, { isValid, placeholder, error, icon }, isAnswered] = useAdditionalRespondentAnswer(question.id, question.hideFromLogic)
  const [focusRef] = useFreeResponseAnswerTakeFocus(question.id, question?.location.assessmentId, question.hideFromLogic, question.show, setForceUpdate)
  const [parsedPreviousResponse] = useState(() => {
    try {
      return answer ? JSON.parse(answer) : null
    } catch (error) {
      console.error('Error decoding previous file upload response', { answer, error, question })
      return null
    }
  })
  const dispatch = useDispatch()
  const [file, setFile] = useState(() =>
    parsedPreviousResponse
      ? new File([], parsedPreviousResponse.fileOriginalName)
      : null
  )
  const lastFileRef = useRef(file)
  const [lastUploadError, setLastUploadError] = useState(null)
  const [uploading, setUploading] = useState(0)
  const uploadCountRef = useRef(0)
  const { passportId, progressId } = useParams()

  const uploadFile = useCallback((file) => {
    if (file) {
      const nextUploadCount = uploadCountRef.current + 1
      uploadCountRef.current = nextUploadCount
      setUploading(nextUploadCount)
      dispatch(uploadFileResponse({ questionId: question.id, file: file, passportId: passportId, progressId: progressId }))
        .unwrap()
        .then((result) => {
          console.info('Upload file result', { result, file })
          notifications.show({
            color: 'green',
            title: 'File Accepted',
            message: 'Your upload was successful!',
            autoClose: 8000
          })
          setLastUploadError(null)
          setAnswer(JSON.stringify({ fileOriginalName: file.name, fileName: result.data }))
        })
        .catch((error) => {
          console.error('Upload file error', { error, file })
          const errorData = error?.data ?? null
          const errorMessage = ((typeof errorData === 'string' || errorData instanceof String) ? errorData : null) ?? 'Unexpected error uploading file'
          notifications.show({
            color: 'red',
            title: 'File Error',
            message: `Your upload was not successful due to an error: ${errorMessage}`,
            autoClose: 8000
          })
          setLastUploadError(errorMessage)
        })
        .finally(() => {
          setUploading((prev) => (prev === nextUploadCount) ? 0 : prev)
        })
    }
  }, [setAnswer, question.id, passportId, progressId, dispatch])

  useEffect(() => {
    if (file !== lastFileRef.current) {
      lastFileRef.current = file
      if (file) {
        uploadFile(file)
      } else {
        setLastUploadError(null)
      }
    }
  }, [file, setAnswer, uploadFile])

  const onFileChange = (value) => {
    console.debug('On file change', { value })
    if ((((value?.size ?? 0) / 1000) / 1000) <= 10.1) {
      setFile(value)
    } else if (value) {
      notifications.show({
        color: 'red',
        title: 'File size too large',
        message: 'The maximum file size is 10 MB.',
        autoClose: 8000
      })
    }
  }

  console.debug('File upload answer updating', { file, answer, question, parsedPreviousResponse, lastUploadError })

  return (
    <>
      <Text size='md'>The following file extensions are allowed: <strong>*.{_.join(FILE_EXTENSIONS, ', *.')}</strong>.</Text>
      <Text size='md'>File must be less than <strong>10 MB</strong>.</Text>
      <Center>
        <FreeResponseIndicator isAnswered={isAnswered} isProcessing={!!uploading} showIndicator={!!uploading || !!file}>
          <FileInput
            ref={focusRef}
            value={file}
            onChange={onFileChange}
            placeholder={placeholder}
            aria-label={`Question ${question.id} Answer: ${getAccessibilityDescription(question.type)}`}
            error={(isValid ? null : error) ?? lastUploadError}
            accept={ACCEPT.toString()}
            leftSection={icon}
            leftSectionPointerEvents='none'
            w='100%'
            miw='15rem'
            maw='75vw'
            size='xl'
            clearable
          />
        </FreeResponseIndicator>
      </Center>
    </>
  )
}

function FillInTheBlankAnswers ({ question, toPDF = false }) {
  const [answer, setAnswer, setForceUpdate, , isAnswered, isProcessing, showIndicator] = useAdditionalRespondentAnswer(question.id, question.hideFromLogic)
  const [focusRef] = useFreeResponseAnswerTakeFocus(question.id, question?.location.assessmentId, question.hideFromLogic, question.show, setForceUpdate)

  return (
    <Center>
      <FreeResponseIndicator isAnswered={isAnswered} isProcessing={isProcessing} showIndicator={showIndicator}>
        <TextInput
          ref={focusRef}
          value={answer}
          onChange={(event) => setAnswer(event.currentTarget.value)}
          placeholder='Enter response'
          aria-label={`Question ${question.id} Answer: ${getAccessibilityDescription(question.type)}`}
          w='100%'
          maw='30vw'
          size='xl'
        />
      </FreeResponseIndicator>
    </Center>
  )
}

function InterviewAnswers ({ question, toPDF = false }) {
  const [answer, setAnswer] = useAdditionalRespondentAnswer(question.id, question.hideFromLogic)
  const [focusRef] = useFreeResponseAnswerTakeFocus(question.id, question?.location.assessmentId, question.hideFromLogic, question.show)

  return (
    <Stack>
      <Textarea
        ref={focusRef}
        value={answer}
        onChange={(event) => setAnswer(event.currentTarget.value)}
        aria-label={`Question ${question.id} Answer: ${getAccessibilityDescription(question.type)}`}
        minRows={7}
        maxRows={7}
        variant='interview'
        autosize
        size='xl'
      />
      <Title order={3}>Rating (please circle)</Title>
      {question.answers.map((elem, index) => <Group key={elem.id} align='flex-start'><Text fw={700} mr='20px' span>{5 - index}</Text><div dangerouslySetInnerHTML={{ __html: elem.content }}></div></Group>)}
    </Stack>
  )
}

const indicatorSize = 8

function FreeResponseIndicator ({ isAnswered, isProcessing, showIndicator, children }) {
  const indicatorColor = isAnswered ? 'blue' : 'red'
  console.debug('Free response indicator updating', { isAnswered, isProcessing, showIndicator })
  return (
    <Indicator position='middle-end' color={indicatorColor} size={indicatorSize} processing={isProcessing} disabled={!showIndicator}>
      {children}
    </Indicator>
  )
}

export function AnswerScore ({ score }) {
  return (
    <NumberFormatter value={score} decimalScale={2} />
  )
}
